import { ProductName, Promo } from '@entities/enums';
import { PromoConfig, PromoName } from '@entities/types';
import {
  absThreeYearOnePoundOfferCodes,
  energyRefixFreeAbsPromoCodes,
  OSPFreeAbsPromoCodes,
  q1HalfPriceAbsPromoCodes,
  q2HalfPriceAbsPromoCodes,
} from '@constants/promotions';
import PromoService from '@services/PromoService';
import {
  beyondPromoFootnotes,
  energyRefixFreeAbsFootnote,
  OSPFreeAbsFootnote,
  absThreeYearOnePoundOfferFootnote,
  buildGenericOfferFootnote,
  evChargerLaunchOfferFootnote,
  epdLaunchOfferFootnote,
} from '@constants/footnotes';
import { generateSequentialPromocode } from '@utils/generateSequentialPromocode';
import { journeyAtom, store } from '@src/store/store';
import { Journey } from '@src/types/Journey';

export const directPromos: PromoConfig[] = [
  {
    order: 1,
    validate: () => {
      const journey = store.get(journeyAtom);
      const is95Excess = journey === Journey.InsuranceHighExcess;
      const isHomeRecover = journey === Journey.HomeRecover;
      const isFlashSale = PromoService.getActiveIndirectPromos().some(
        (promo) => promo.name === PromoName.Q1202512MonthsHalfPrice
      );
      return !isFlashSale && !is95Excess && !isHomeRecover;
    },
    name: PromoName.Q120258MonthsHalfPrice,
    startDate: new Date('2025-01-07T00:00:00'),
    endDate: new Date('2025-03-20T23:59:59'),
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    footnote: buildGenericOfferFootnote({
      endDate: new Date('2025-03-20T23:59:59'),
      id: 'q1-2025-8months-halfprice',
    }),
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      duration: 8,
      discountPercentage: 50,
      codes: [...generateSequentialPromocode(Promo.Q1SAVE, 45), Promo.Q125BEYOND],
    },
  },
  {
    order: 2,
    startDate: new Date('2024-01-09T00:00Z'),
    name: PromoName.Q1AbsDirectOffer,
    appliesTo: [ProductName.ABS],
    promoOptions: {
      type: 'PERCENTDISCOUNT',
      percentage: 50,
      codes: q1HalfPriceAbsPromoCodes,
    },
  },
  {
    order: 4,
    name: PromoName.EnergyRefixFreeABS,
    startDate: new Date('2024-08-28T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: energyRefixFreeAbsFootnote.energyRefixFreeAbs,
    promoOptions: {
      type: 'FREE',
      codes: energyRefixFreeAbsPromoCodes,
    },
  },
  {
    order: 5,
    name: PromoName.BeyondFreeAbs,
    startDate: new Date('2024-04-16T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: beyondPromoFootnotes?.beyondFreeAbs,
    promoOptions: {
      type: 'FREE',
      codes: q2HalfPriceAbsPromoCodes,
    },
  },
  {
    order: 5,
    name: PromoName.OSPFreeABS,
    startDate: new Date('2024-10-21T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: OSPFreeAbsFootnote?.OSPFreeAbs,
    promoOptions: {
      type: 'FREE',
      codes: OSPFreeAbsPromoCodes,
    },
  },
  {
    order: 5,
    name: PromoName.AbsThreeYearOnePoundOffer,
    startDate: new Date('2024-11-11T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: absThreeYearOnePoundOfferFootnote?.absThreeYearOnePound,
    promoOptions: {
      type: 'FIXEDTERMFIXEDPRICE',
      duration: 36,
      value: 1,
      codes: absThreeYearOnePoundOfferCodes,
    },
  },
  {
    order: 6,
    name: PromoName.EvChargerLaunchFiftyPercentOff,
    startDate: new Date('2025-01-14T00:00:00'),
    appliesTo: [ProductName.EV_CHARGER_COVER],
    footnote: evChargerLaunchOfferFootnote.evChargerLaunchHalfPriceOffer,
    promoOptions: {
      type: 'PERCENTDISCOUNT',
      percentage: 50,
      codes: [...generateSequentialPromocode('EVHP', 20)],
    },
  },
  {
    order: 7,
    name: PromoName.EvChargerLaunchFree,
    startDate: new Date('2025-01-14T00:00:00'),
    appliesTo: [ProductName.EV_CHARGER_COVER],
    footnote: evChargerLaunchOfferFootnote.evChargerLaunchFreeOffer,
    promoOptions: {
      type: 'FREE',
      codes: [
        ...generateSequentialPromocode('EV', 20),
        ...generateSequentialPromocode('EV20-', 20),
      ],
    },
  },
  {
    order: 8,
    startDate: new Date('2025-03-26T00:00:00'),
    endDate: new Date('2099-12-31T23:59:59'),
    name: PromoName.EpdLaunchSixMonthsHalfPrice,
    appliesTo: [ProductName.EPD_COVER],
    footnote: epdLaunchOfferFootnote.epdLaunchHalfPriceOffer,
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      duration: 6,
      discountPercentage: 50,
      codes: [...generateSequentialPromocode('EPD', 10)],
    },
  },
];
