import { noop } from 'lodash';
import React, {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { WindowLocation } from '@reach/router';
import { useAtom } from 'jotai';

import { useLocalStorage } from './LocalStorageContext';

import { Page, Promo } from '@entities/enums';
import PromoService from '@services/PromoService';
import { useAuth } from '@hooks/useAuth';
import { getUrlParam } from '@utils/getUrlParam';
import { trackingAtom, utmTrackingAtom } from '@src/store/store';

interface ContextValue {
  promo?: Promo;
  accountId?: string;
  loading: boolean;
  setPromo: Dispatch<React.SetStateAction<Promo | undefined>>;

  setLoading(loading: boolean): void;

  getCurrentPage(): string;

  navigateToLogin(pageToReturnTo: Page): void;

  navigateToLoginv2(): void;

  onPageLoad(pageName: string): void;

  setCurrentPage(name: string): void;

  storeSignupInLocalStorage(): void;

  verifyNoSignupInLocalStorage(): void;
}

const defaultValue: ContextValue = {
  loading: false,
  setLoading: noop,
  getCurrentPage: () => '',
  navigateToLogin: noop,
  navigateToLoginv2: noop,
  onPageLoad: noop,
  setCurrentPage: noop,
  setPromo: noop,
  storeSignupInLocalStorage: noop,
  verifyNoSignupInLocalStorage: noop,
};

const AppContext = createContext(defaultValue);

interface ProviderProps {
  location: WindowLocation;
}

export const AppContextProvider: React.FC<ProviderProps> = ({ children, location }) => {
  const [loading, setLoading] = useState(false);
  const [promo, setPromo] = useState<Promo | undefined>();
  const [tracking, setTracking] = useAtom(trackingAtom);
  const [utmTracking, setUtmTracking] = useAtom(utmTrackingAtom);
  const currentPage = useRef('');

  const localStorage = useLocalStorage();

  const { user } = useAuth();

  const accountId = user?.accountId;

  const verifyNoSignupInLocalStorage = useCallback(() => {
    const localStore = localStorage.load();
    localStore.signedUpAccounts.includes(accountId);
  }, [accountId, localStorage]);

  const storeSignupInLocalStorage = useCallback(() => {
    localStorage.save({
      signedUpAccounts: [...localStorage.load().signedUpAccounts, accountId],
    });
  }, [accountId, localStorage]);

  const value = useMemo((): ContextValue => {
    const onPageLoad = (pageName: string) => {
      currentPage.current = pageName;

      if (!tracking.source) {
        setTracking({ source: getUrlParam('source') });
      }

      if (!utmTracking) {
        setUtmTracking({
          medium: getUrlParam('utm_medium') ?? 'Direct',
          source: getUrlParam('utm_source'),
          content: getUrlParam('utm_content'),
          campaign: getUrlParam('utm_campaign'),
        });
      }

      setLoading(false);
      setPromo(PromoService.getPromo());
    };

    return {
      accountId,
      loading,
      setLoading,
      promo,
      setPromo,
      onPageLoad,
      navigateToLogin: (pageToReturnTo: Page = Page.HOME) => {
        const search = new URLSearchParams(location.search).toString();
        const params = encodeURIComponent(`&selectedPage=${pageToReturnTo}&${search}`);

        window.location.replace(process.env.GATSBY_PORTAL_URL + params);
      },
      navigateToLoginv2: () => {
        const currentUrl = window.location.href;

        window.location.replace(
          `${process.env.GATSBY_MY_OVO_URL}/?redirect=${encodeURI(currentUrl)}`
        );
      },

      verifyNoSignupInLocalStorage,
      storeSignupInLocalStorage,
      getCurrentPage: () => currentPage.current,
      setCurrentPage: (name: string) => {
        currentPage.current = name;
      },
    };
  }, [
    accountId,
    loading,
    setLoading,
    promo,
    location.search,
    verifyNoSignupInLocalStorage,
    storeSignupInLocalStorage,
    tracking.source,
    setTracking,
    utmTracking,
    setUtmTracking,
  ]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
