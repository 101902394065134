import { createStore } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { focusAtom } from 'jotai-optics';
import { v4 as uuidv4 } from 'uuid';

import { TrackingFields } from '@services/CorgiService';
import { AutoRenewalOption, BoilerBrand, BoilerBrandExclusions, CoverType } from '@entities/enums';
import { AccountId } from '@entities/types';
import { Repair } from '@services/RepairService';
import { SignupDto } from '@src/generated/SignupDto';
import { getUrlParam } from '@utils/getUrlParam';
import { ProductCode } from '@src/types/Quote';
import { LocalStore } from '@contexts/LocalStorageContext';
import { Journey } from '@src/types/Journey';

export interface PersonalDetails {
  title: SignupDto['customerDetails']['title'] | '';
  forename: string;
  surname: string;
  telephone: string;
  email: string;
  addressLine1: string;
  addressLine2?: string;
  postcode: string;
  town: string;
}

export interface BoilerDetails {
  applianceId: string;
  isNotSupportedBoiler?: BoilerBrandExclusions;
  make: BoilerBrand | '';
  boilerAge: string;
  ownership: boolean;
  notGuesthouse: boolean;
}

export interface SensitivePaymentDetails {
  nameOnAccount: string;
  accountNumber: string;
  sortCode: string;
}

export interface PaymentDetails {
  accountOwner: boolean;
  termsAndConditions: boolean;
  rewardTermsAndConditions: boolean;
  homeRecoverTerms: boolean;
  paperless: boolean;
  autoRenewal: AutoRenewalOption;
}

export interface MarketingPermissions {
  email: boolean;
  sms: boolean;
  phone: boolean;
  post: boolean;
}

export interface EVChargerEligibility {
  evChargerOwnership: boolean;
  evChargerUseGenerateIncome: boolean;
  evChargerOutput: boolean;
}
export interface EPDEligibility {
  homeOwnership: boolean;
  notGuesthouse: boolean;
}

interface Tracking {
  source: string | null;
}

export interface Store {
  testUser: boolean;
  excess: number;
  accountId: AccountId | undefined;
  selectedCoverType: CoverType | undefined;
  orderReference: string | undefined;
  signupComplete: boolean;
  personalDetails: PersonalDetails;
  boilerDetails: BoilerDetails;
  paymentDetails: PaymentDetails;
  marketingPermissions: MarketingPermissions;
  sensitivePaymentDetails: SensitivePaymentDetails;
  promoCode: string | undefined;
  tracking: Tracking;
  utmTracking: TrackingFields | undefined;
  isAgent: boolean;
  repair: Repair | undefined;
  isCreditEligible: boolean;
  creditType: 'SVT' | 'FIXED' | undefined;
  tariffName: string | undefined;
  evCharger: EVChargerEligibility;
  epdEligibility: EPDEligibility;
  selectedProduct?: ProductCode;
  journey: Journey;
}

const defaultMarketingPermissions = {
  email: false,
  sms: false,
  phone: true,
  post: true,
};

const defaultPersonalDetails: PersonalDetails = {
  title: '',
  forename: '',
  surname: '',
  telephone: '',
  email: '',
  addressLine1: '',
  addressLine2: '',
  postcode: '',
  town: '',
};

const defaultEVCharger: EVChargerEligibility = {
  evChargerOutput: false,
  evChargerOwnership: false,
  evChargerUseGenerateIncome: false,
};

const defaultEPDEligibility = {
  homeOwnership: false,
  notGuesthouse: false,
};

const defaultBoilerDetails: BoilerDetails = {
  applianceId: uuidv4(),
  make: '',
  boilerAge: '',
  ownership: false,
  notGuesthouse: false,
  isNotSupportedBoiler: BoilerBrandExclusions.NOTEXCLUDED,
};

const defaultSensitivePaymentDetails: SensitivePaymentDetails = {
  nameOnAccount: '',
  accountNumber: '',
  sortCode: '',
};

const defaultPaymentDetails: PaymentDetails = {
  accountOwner: false,
  termsAndConditions: false,
  rewardTermsAndConditions: false,
  homeRecoverTerms: false,
  paperless: true,
  autoRenewal: AutoRenewalOption.YES,
};

const defaultTracking: Tracking = {
  source: getUrlParam('source'),
};

const browserStorageKey = 'homePlan:store';

let storage: ReturnType<typeof createJSONStorage<Store>> | undefined;

if (typeof window !== 'undefined') {
  storage = createJSONStorage<Store>(() => sessionStorage);
}

let homePlanStore: Store | undefined;

export let store = createStore();

export const resetStore = () => {
  store = createStore();
};

if (typeof window !== 'undefined') {
  const homePlanStoreValue = sessionStorage.getItem(browserStorageKey);
  homePlanStore = homePlanStoreValue && JSON.parse(homePlanStoreValue);
}

const defaultLocalStore: LocalStore = {
  signedUpAccounts: [],
  testUser: false,
};

export const defaultStore: Store = {
  personalDetails: homePlanStore?.personalDetails || defaultPersonalDetails,
  boilerDetails: homePlanStore?.boilerDetails || defaultBoilerDetails,
  sensitivePaymentDetails: homePlanStore?.sensitivePaymentDetails || defaultSensitivePaymentDetails,
  paymentDetails: homePlanStore?.paymentDetails || defaultPaymentDetails,
  marketingPermissions: homePlanStore?.marketingPermissions || defaultMarketingPermissions,
  excess: homePlanStore?.excess ?? 60,
  signupComplete: homePlanStore?.signupComplete || false,
  promoCode: homePlanStore?.promoCode,
  tracking: homePlanStore?.tracking || defaultTracking,
  isCreditEligible: homePlanStore?.isCreditEligible || false,
  isAgent: homePlanStore?.isAgent || false,
  testUser: homePlanStore?.testUser || false,
  selectedCoverType: homePlanStore?.selectedCoverType,
  selectedProduct: homePlanStore?.selectedProduct,
  accountId: homePlanStore?.accountId,
  creditType: homePlanStore?.creditType,
  repair: homePlanStore?.repair,
  tariffName: homePlanStore?.tariffName,
  utmTracking: homePlanStore?.utmTracking,
  orderReference: homePlanStore?.orderReference,
  evCharger: homePlanStore?.evCharger || defaultEVCharger,
  journey: homePlanStore?.journey || Journey.Insurance,
  epdEligibility: homePlanStore?.epdEligibility || defaultEPDEligibility,
};

export const homePlanStoreAtom = atomWithStorage<Store>(browserStorageKey, defaultStore, storage);
export const homePlanStoreLocalStorageAtom = atomWithStorage<LocalStore>(
  browserStorageKey,
  defaultLocalStore,
  createJSONStorage<LocalStore>(() => localStorage)
);
export const personalDetailsAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('personalDetails')
);
export const boilerDetailsAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('boilerDetails')
);
export const sensitivePaymentDetailsAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('sensitivePaymentDetails')
);
export const paymentDetailsAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('paymentDetails')
);
export const marketingPermissionsAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('marketingPermissions')
);
export const excessAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('excess'));
export const signupCompleteAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('signupComplete')
);
export const promoCodeAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('promoCode'));
export const trackingAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('tracking'));
export const isCreditEligibleAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('isCreditEligible')
);
export const isAgentAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('isAgent'));
export const testUserAtom = focusAtom(homePlanStoreLocalStorageAtom, (optic) =>
  optic.prop('testUser')
);
export const repairAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('repair'));

export const selectedCoverTypeAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('selectedCoverType')
);

export const selectedProductAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('selectedProduct')
);
export const orderReferenceAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('orderReference')
);
export const utmTrackingAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('utmTracking'));
export const tariffNameAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('tariffName'));
export const creditTypeAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('creditType'));

export const evChargerEligibilityAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('evCharger')
);

export const journeyAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('journey'));

export const epdEligibilityAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('epdEligibility')
);
