import { FormikErrors } from 'formik';
import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { useCallback, useEffect, useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { useAppContext } from '@contexts/AppContext';
import { MixpanelEvents } from '@entities/enums';
import {
  creditTypeAtom,
  excessAtom,
  homePlanStoreAtom,
  selectedCoverTypeAtom,
  tariffNameAtom,
  testUserAtom,
} from '@src/store/store';
import { useAuth } from '@hooks/useAuth';

interface TrackProperties {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

type FormErrors = FormikErrors<object>;

interface CustomMixpanel extends Mixpanel {
  trackFormErrors: (errors: FormErrors) => void;
  loaded?: boolean;
}

mixpanel.init(process.env.GATSBY_MIXPANEL_TOKEN as string, {
  api_host: process.env.GATSBY_MIXPANEL_HOST,
  track_pageview: true,
});

export const useCustomMixpanel = (): CustomMixpanel => {
  const { getCurrentPage, promo } = useAppContext();
  const store = useAtomValue(homePlanStoreAtom);
  const { user, fetched } = useAuth();

  const track = useCallback(
    (eventName: string, properties: TrackProperties) => {
      mixpanel.track(eventName, {
        accountId: user?.accountId,
        promoCode: promo,
        pageName: getCurrentPage(),
        coverType: store.selectedCoverType,
        excessSelected: store.excess,
        source: store.tracking.source,
        ...properties,
      });
    },
    [getCurrentPage, promo, user, store.selectedCoverType, store.tracking?.source, store.excess]
  );

  const trackFormErrors = useCallback(
    (errors: FormErrors) => {
      Object.entries(errors).map(([field, error]) => {
        track(MixpanelEvents.ERROR, {
          field,
          error,
        });
      });
    },
    [track]
  );

  return useMemo(
    () => ({
      ...mixpanel,
      loaded: fetched,
      track,
      trackFormErrors,
    }),
    [fetched, track, trackFormErrors]
  );
};

export const MixpanelProvider: React.FC = ({ children }) => {
  // eslint-disable-next-line no-console
  mixpanel.identify();
  const testUser = useAtomValue(testUserAtom);
  const selectedCoverType = useAtomValue(selectedCoverTypeAtom);
  const excess = useAtomValue(excessAtom);
  const tariffName = useAtomValue(tariffNameAtom);
  const creditType = useAtomValue(creditTypeAtom);

  const { user } = useAuth();

  const extraProperties = useMemo(() => {
    return {
      coverType: selectedCoverType,
      accountId: user?.accountId,
      excessSelected: excess,
      creditType: creditType,
      tariffName: tariffName,
      homePlanTestUser: testUser,
    };
  }, [creditType, excess, selectedCoverType, tariffName, testUser, user?.accountId]);

  useEffect(() => {
    mixpanel.people.set({ ...extraProperties });
  }, [extraProperties]);

  return <>{children}</>;
};
