import {
  buildGenericOfferFootnote,
  buildHighExcessFlashSalePromoFootnote,
  homeRecoverFreeRepair,
} from '@constants/footnotes';
import { ProductName } from '@entities/enums';
import { PromoConfig, PromoName } from '@entities/types';
import {
  creditTypeAtom,
  isCreditEligibleAtom,
  journeyAtom,
  store,
  tariffNameAtom,
} from '@src/store/store';
import { Journey } from '@src/types/Journey';

export const indirectPromos: PromoConfig[] = [
  {
    order: 1,
    validate: () => {
      const journey = store.get(journeyAtom);
      const is95Excess = journey === Journey.InsuranceHighExcess;

      return is95Excess;
    },
    name: PromoName.FlashSaleSixMonthsHalfPrice,
    startDate: new Date('2025-08-12T00:00:00'),
    endDate: new Date('2025-09-11T23:59:59'),
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    footnote: buildHighExcessFlashSalePromoFootnote({
      endDate: new Date('2025-09-11T23:59:59'),
      id: 'flash-sale-6-months-halfprice-3',
    }),
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      duration: 6,
      discountPercentage: 50,
    },
  },
  {
    order: 1,
    validate: () => {
      const journey = store.get(journeyAtom);
      const is95Excess = journey === Journey.InsuranceHighExcess;

      return is95Excess;
    },
    name: PromoName.FlashSaleSixMonthsHalfPrice,
    startDate: new Date('2025-05-27T00:00:00'),
    endDate: new Date('2025-06-26T23:59:59'),
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    footnote: buildHighExcessFlashSalePromoFootnote({
      endDate: new Date('2025-06-26T23:59:59'),
      id: 'flash-sale-6-months-halfprice-2',
    }),
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      duration: 6,
      discountPercentage: 50,
    },
  },
  {
    order: 1,
    validate: () => {
      const journey = store.get(journeyAtom);
      const is95Excess = journey === Journey.InsuranceHighExcess;

      return is95Excess;
    },
    name: PromoName.FlashSaleSixMonthsHalfPrice,
    startDate: new Date('2025-03-25T00:00:00'),
    endDate: new Date('2025-04-24T23:59:59'),
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    footnote: buildHighExcessFlashSalePromoFootnote({
      endDate: new Date('2025-04-24T23:59:59'),
      id: 'flash-sale-6-months-halfprice-1',
    }),
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      duration: 6,
      discountPercentage: 50,
    },
  },
  {
    order: 1,
    validate: () => {
      const journey = store.get(journeyAtom);
      const is95Excess = journey === Journey.InsuranceHighExcess;

      return !is95Excess;
    },
    name: PromoName.Q1202512MonthsHalfPrice,
    startDate: new Date('2025-01-21T00:00:00'),
    endDate: new Date('2025-02-11T23:59:59'),
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    footnote: buildGenericOfferFootnote({
      endDate: new Date('2025-02-11T23:59:59'),
      id: 'q1-2025-12months-halfprice',
    }),
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      duration: 12,
      discountPercentage: 50,
    },
  },
  {
    order: 102,
    name: PromoName.HomeRecoverFreeRepair,
    appliesTo: [ProductName.COMPLETE],
    addon: true,
    startDate: new Date('2024-09-17T00:00Z'),
    endDate: new Date('2025-03-20T23:59:59'),
    promoOptions: {
      type: 'FREEREPAIR',
    },
    footnote: homeRecoverFreeRepair.promo,
    validate: () => {
      const journey = store.get(journeyAtom);
      const isHomeRecover = journey === Journey.HomeRecover;
      const tariffName = store.get(tariffNameAtom);
      return isHomeRecover && tariffName === undefined;
    },
  },
  {
    order: 3,
    validate: () => {
      const journey = store.get(journeyAtom);
      const isHomeRecover = journey === Journey.HomeRecover;
      const is95Excess = journey === Journey.InsuranceHighExcess;
      const isCreditEligible = store.get(isCreditEligibleAtom);
      const creditType = store.get(creditTypeAtom);

      return Boolean(!isHomeRecover && !is95Excess && isCreditEligible && creditType === 'SVT');
    },
    name: PromoName.EnergyCreditSVT,
    addon: true,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    endDate: new Date('2024-09-17T00:00Z'),
    promoOptions: {
      type: 'ENERGYCREDIT',
      amounts: {
        [ProductName.ESSENTIALS]: 50,
        [ProductName.STARTER]: 50,
        [ProductName.COMPLETE]: 50,
      },
    },
  },
  {
    order: 3,
    validate: () => {
      const journey = store.get(journeyAtom);
      const isHomeRecover = journey === Journey.HomeRecover;
      const is95Excess = journey === Journey.InsuranceHighExcess;
      const isCreditEligible = store.get(isCreditEligibleAtom);
      const creditType = store.get(creditTypeAtom);

      return Boolean(!isHomeRecover && !is95Excess && isCreditEligible && creditType === 'FIXED');
    },
    startDate: new Date('2024-02-28T09:00Z'),
    endDate: new Date('2024-09-17T00:00Z'),
    name: PromoName.EnergyCreditFixed,
    addon: true,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    promoOptions: {
      type: 'ENERGYCREDIT',
      amounts: {
        [ProductName.ESSENTIALS]: 100,
        [ProductName.STARTER]: 100,
        [ProductName.COMPLETE]: 100,
      },
    },
  },
  {
    order: 101,
    name: PromoName.Q120256MonthsHalfPrice,
    startDate: new Date('2025-01-07T00:00Z'),
    endDate: new Date('2025-03-20T23:59:59'),

    appliesTo: [ProductName.COMPLETE, ProductName.ESSENTIALS, ProductName.STARTER],
    footnote: buildGenericOfferFootnote({
      endDate: new Date('2025-03-20T23:59:59'),
      id: 'q1-2025-6months-halfprice',
    }),
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      discountPercentage: 50,
      duration: 6,
    },

    validate: () => {
      const journey = store.get(journeyAtom);
      const is95Excess = journey === Journey.InsuranceHighExcess;

      return !is95Excess;
    },
  },
];
