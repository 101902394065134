import { Stack, CTAButton, TextField } from '@ovotech/element';
import { useEffect, useState } from 'react';

interface HomePlanStore {
  signedUpAccounts: string[];
  testUser: boolean;
  optimizelyUserId: string;
}

export const MockOptimizelyUserId = () => {
  const [homePlanStore, setHomePlanStore] = useState<HomePlanStore | null>(null);
  const [mockOptimizelyUserId, setMockOptimizelyUserId] = useState('');
  const homePlanStoreName = 'homePlan:store';

  useEffect(() => {
    const homePlanStore = localStorage.getItem(homePlanStoreName);
    if (homePlanStore) {
      const homePlanStoreObject = JSON.parse(homePlanStore);
      setHomePlanStore(homePlanStoreObject);
      setMockOptimizelyUserId(homePlanStoreObject.optimizelyUserId);
    }
  }, [setHomePlanStore]);

  const submitMockOptimizelyUserId = (optimizelyUserId = '') => {
    const newHomePlanStore = { ...homePlanStore, optimizelyUserId };
    localStorage.setItem(homePlanStoreName, JSON.stringify(newHomePlanStore));
    window.location.reload();
  };

  const resetMockOptimizelyUserId = () => {
    localStorage.removeItem(homePlanStoreName);
    submitMockOptimizelyUserId('');
  };

  return (
    <Stack spaceBetween={4}>
      <TextField
        id="mock-optimizely-user-id"
        label="Mock Optimizely User ID"
        value={mockOptimizelyUserId}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setMockOptimizelyUserId(e.target.value)
        }
      />
      <div>
        <CTAButton onClick={() => submitMockOptimizelyUserId(mockOptimizelyUserId)}>
          Submit
        </CTAButton>
      </div>
      <div>
        <CTAButton variant="secondary" onClick={resetMockOptimizelyUserId}>
          Reset
        </CTAButton>
      </div>
    </Stack>
  );
};
